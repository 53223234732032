// if (module.hot) {
//   module.hot.accept();
// }

import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import Lightense from "lightense-images";
import { default as stxrx } from "./js/store";
import Observers from "./js/observers";
import Progress from "./js/minimap";

/**
 *
 *  🚨
 *
 */
let STORE = createStore(stxrx, applyMiddleware(thunk));
STORE.subscribe(loop);
let unsubscribe = STORE.subscribe(loop);

/**
 *
 *  🔁
 *
 */

function loop() {
  const achieved = slice(STORE, "unlocked");
  const loading = slice(STORE, "loading");
  Progress(achieved, loading);
  if (achieved.length === 5) {
    unsubscribe();
  }
}

document.addEventListener("DOMContentLoaded", function() {
  ponyfill();

  const sections = $$("article");
  Observers(sections, STORE);

  const shots = $$("article img");
  Lightense(shots, {
    time: 700,
    padding: 110,
    keyboard: true,
    cubicBezier: "cubic-bezier(.2, 0, .1, 1)",
    zIndex:8
  });

  loop();
});

function $(selector) {
  return document.querySelector(selector);
}

function $$(selector) {
  return [].slice.call(document.querySelectorAll(selector));
}

function slice(store, key) {
  return store.getState()[key];
}

function ponyfill() {
  if (!String.prototype.includes) {
    String.prototype.includes = function(search, start) {
      "use strict";
      if (typeof start !== "number") {
        start = 0;
      }

      if (start + search.length > this.length) {
        return false;
      } else {
        return this.indexOf(search, start) !== -1;
      }
    };
  }

  if (!String.prototype.startsWith) {
    String.prototype.startsWith = function(searchString, position) {
      position = position || 0;
      return this.substr(position, searchString.length) === searchString;
    };
  }
}
