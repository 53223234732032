const ACTIONS = {
  SHOW_NOTIFICATION: "SHOW_NOTIFICATION",
  HIDE_NOTIFICATION: "HIDE_NOTIFICATION",
  UNLOCKED: "UNLOCKED",
  TOGGLE_LOADING: "TOGGLE_LOADING"
};

const initialState = {
  notifications: [],
  unlocked: [],
  loading: false
};

function feedback(id, msg, shape) {
  return { type: ACTIONS.SHOW_NOTIFICATION, payload: { id, msg, shape } };
}
function unfeedback(id) {
  return { type: ACTIONS.HIDE_NOTIFICATION, payload: id };
}

let nextId = 1;
function notify({ msg, sticky = false, shape = 22 }) {
  return function(dispatch, getState) {
    const id = nextId++;
    dispatch(feedback(sticky ? 0 : id, msg, shape));
    if (!sticky) {
      setTimeout(() => {
        dispatch(unfeedback(id));
      }, 9000);
    }
  };
}
function simulation(section = false) {
  return { type: ACTIONS.TOGGLE_LOADING, payload: section };
}

function next(section) {
  return { type: ACTIONS.UNLOCKED, payload: section };
}

function unlocked(section) {
  return function(dispatch, getState) {
    dispatch(simulation(section));
    setTimeout(() => {
      dispatch(next(section));
    }, 1100);
  };
}

// function modeHandler(e, store) {
//   e.preventDefault();
//   store.dispatch(toggleMode());
// }

function notificationsHandler(e, store) {
  let id = e.target.dataset.notificationid;
  store.dispatch(unfeedback(id));
}
export { notify, unfeedback, unlocked, notificationsHandler };

export default function(state = initialState, action = {}) {
  switch (action.type) {
    case ACTIONS.SHOW_NOTIFICATION:
      const notification = action.payload;
      let intent = [...state.notifications, notification];
      let reality = [...new Set(intent)];
      return {
        ...state,
        notifications: reality
      };

    case ACTIONS.TOGGLE_LOADING:
      return {
        ...state,
        loading: action.payload ? action.payload : ""
      };

    case ACTIONS.HIDE_NOTIFICATION:
      return {
        ...state,
        notifications: state.notifications.filter(
          n => n.id !== Number(action.payload)
        )
      };

    case ACTIONS.UNLOCKED:
      let addition = action.payload;
      let dirty = [...state.unlocked, addition];
      let clean = [...new Set(dirty)];
      return {
        ...state,
        unlocked: clean,
        loading: false
      };
    default:
      return state;
  }
}
