import { html, render, svg } from "lit-html";
import Shape from "./shape";
import "./minimap.css";

let COLORS = {
  PRISTINE: "#7052ce", //"var(--bg)",
  BLACK: "#7052ce", // "#444",
  WIN: "#c62a88" //"var(--accent)"
};

function Progress(achieved, loading) {
  render(
    Component(achieved, loading),
    document.querySelector(".progress-container")
  );
}

export default Progress;

let opts = [
  { step: 1, label: "Hola", uri: "latest", sides: 3 },
  { step: 2, label: "Influence", uri: "influence", sides: 4 },
  { step: 3, label: "Tvrbo", uri: "tvrbo", sides: 5},
  { step: 4, label: "Ads", uri: "ads", sides: 6 },
  { step: 5, label: "Games", uri: "games", sides: 7 },
  { step: 6, label: "FFF", uri: "fff", sides: 8 },
  // { step: 6, label: "Performance", uri: "perf", sides: 8 }

  // { step: 7, label: "OSS", uri: "oss", sides: 9 }
];

function Component(achieved, loading) {
  return html`
    ${opts.map((opt, i) =>
      Section({
        isLoading: loading && loading.startsWith(opt.uri) ? true : false,
        achieved: achieved.includes(opt.uri) ? true : false,
        uri: opt.uri,
        label: opt.label,
        step: opt.step,
        sides: opt.sides
      })
    )}
  `;
}

function Section({
  achieved = false,
  isLoading = false,
  step,
  sides,
  uri,
  label
}) {
  if(!achieved && !isLoading) {
       return html`
      <div progress-section class="col-center align-center">
      <a
      tabindex="-1"
          data-tooltip=${label}
          href="#${uri}"
          class="stretched-link txt-center txt-pointer col-center align-center"
        >
        </a
      </div>
    `;
  }

  if (isLoading) {
    return html`
      <div progress-section class="col-center align-center">
      <a
      tabindex="-1"
          data-tooltip=${label}
          href="#${uri}"
          class="stretched-link txt-center txt-pointer col-center align-center"
        >
        <div spinner></div>
        </a
      </div>
    `;
  } else {
    return html`
      <div progress-section="${achieved ? "achieved" : ""}">
        <a
        tabindex="-1"
          data-tooltip=${label}
          href="#${uri}"
          class="stretched-link txt-center txt-pointer col-center align-center font-number"
        >
         0${step}
        </a>
      </div>
    `;
  }
}

function Icon(step, sides, achieved, special) {
  const opts = {
    vertices: 6, // sides
    fill: achieved ? COLORS.WIN : special ? COLORS.WIN : COLORS.BLACK,
    size: 15
  };
  return svg`
      ${Shape(opts)}
    `;
}
