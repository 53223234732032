import { notify, unlocked } from "./store";

function $$(selector) {
  return [].slice.call(document.querySelectorAll(selector));
}

function Observers(lazySections, store) {
  const opts = {
    // root: document.querySelector("#main"),
    threshold: 0.5
  };
  let lazySectionsObserver = new IntersectionObserver(function(
    entries,
    observer
  ) {
    for (const entry of entries) {
      if (entry.isIntersecting) {
        const lazySection = entry.target;
        const section = entry.target.id;

        lazySection.classList.add("done");
        lazySectionsObserver.unobserve(lazySection);
        store.dispatch(unlocked(section));

        if (["ads", "games", "fff"].includes(section)) {
          const lazyImages = $$(`#${section} img.lazy`);
          lazyImages.map(img => (img.src = img.dataset.src));
        }
        const shape = entry.target.dataset.shape;
        if (shape) {
          store.dispatch(
            notify({
              id: entry.target.id,
              msg: entry.target.dataset.notification,
              shape
            })
          );
        }
      }
    }
  },
  opts);

  for (let lazySection of lazySections) {
    lazySectionsObserver.observe(lazySection);
  }
}

export default Observers;
